import { Box, Typography } from "@mui/material";

export const PrintFooter = () => {
    return (
        <Box
            id={"pageFooter"}
            sx={{
                display: "none",
                width: "100%",
                background: "#1e22aa",
                borderBottom: "5px solid #55c1e9",
                "@media print": {
                    display: "block",
                },
            }}
        >
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                height: "25px",
                padding: "0px 100px",
                
            }}>
                <Typography sx={{fontSize: "10px"}}>©2024 HIMSS. All Rights Reserved.</Typography>
            </Box>
        </Box>
    );
};