import { useAuth0 } from '@auth0/auth0-react';
import BaseLayout from '../../layout/BaseLayout.component';

const Forbidden = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    return (
            <BaseLayout>
                <h3>Forbidden</h3>
                { !isAuthenticated && (
                    <div>
                        <h3>You aren't supposed to be here.  Please login.</h3>
                        <button onClick={() => loginWithRedirect()}>Log In</button>
                    </div>
                )}
                { isAuthenticated && (
                    <div>
                        <h3>You aren't supposed to be here.  Please contact support to get permission to this resource.</h3>
                    </div>
                )}
            </BaseLayout>
    );
};
  
export default Forbidden;