import { Autocomplete, AutocompleteChangeReason, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import { CreateDefaultAssessmentDetail, AssessmentDetailModel, AssessmentDetailModelIsValid, AssessmentDetailValidationModel } from "./Assessment.models";
import { DeleteAssessmentDetail, FileAssessmentDetail, GetAssessmentDetail } from "./Assessment.service";
import TypeAhead from "../../components/formControls/TypeAheadBase.component";
import TypeAheadMulti from "../../components/formControls/TypeAheadMultiBase.component";
import NumericDropDownModel from "../../models/NumericDropDown.model";
import moment from "moment";
import LanguageDropDown from "../../components/formControls/LanguageDropDown.component";
import GoBack from "../../components/rightMenuControls/GoBack.component";
import AssessmentDetailValidationModal from "./AssessmentDetailValidationModal.component";
import DeleteDialog from "../../components/formComponents/DeleteDialog.component";
import ResendEmailDialog from "./ResendEmailDialog.component";
import { GetAssessmentTypeTypeAheadSearch } from "../../services/AssessmentType.service";
import { GetFacilityTypeAheadSearch } from "../../services/FacilityTypeAhead.service";
import { GetDHTPTypeAhead } from "../../services/DigitalHealthTechnologyPartner.service";
import { GetUserTypeAheadSearch, userHasPermission } from "../../services/User.service";
import { useIsDirtyPrompt } from "../../components/formComponents/useIsDirtyPrompt.component";
import { GetLanguageOptions } from "../../services/Language.service";
import RemoveFacilitiesDialog from "./RemoveFacilitesDialog.component";
import { CreateDefaultDocumentSearchRequest } from "../Documents/Document.models";
import { GetAccountDetail } from "../Accounts/Account.service";
import { CreateUserAccountListRequest } from "../UserAccount/UserAccount.models";

const AssessmentDetail = () => {
    const {appUser} = useApiAuthorization();
    const {assessmentID} = useParams();
    const [loading, setLoading] = React.useState<boolean>(false);
    const location = useLocation();
    const [assessmentDetail, setAssessmentDetail] = React.useState<AssessmentDetailModel>(CreateDefaultAssessmentDetail(appUser, location));
    const initialAssessmentDetail = React.useRef(assessmentID === 'new' ? assessmentDetail : null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [showValidationText, setShowValidationText] = React.useState<boolean>(false);
    const [showValidationModal, setShowValidationModal] = React.useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
    const [showResendEmailDialog, setShowResendEmailDialog] = React.useState<boolean>(false);
    const [assessmentDetailValidationModel, setAssessmentDetailValidationModel] = React.useState<AssessmentDetailValidationModel>();
    const [showResendEmailDialogOnSave, setShowResendEmailDialogOnSave] = React.useState<boolean>(false);
    const [showRemoveFacilites, setShowRemoveFacilites] = React.useState<boolean>(false);
    const [showTruncatedFacilities, setShowTruncatedFacilities] = React.useState<boolean>(false);
    const [showSESLink, setShowSESLink] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const isDirty = useIsDirtyPrompt(initialAssessmentDetail.current, assessmentDetail);

    React.useEffect(() => {
        let assessmentIDNumber = parseInt(assessmentID!);
        if(isNaN(assessmentIDNumber) === false && !showRemoveFacilites){
            setLoading(true);
            GetAssessmentDetail(assessmentIDNumber, appUser!.token)
                .then((response) => response.json())
                .then((data) => {
                    setAssessmentDetail(data);
                    initialAssessmentDetail.current = data;

                    if (initialAssessmentDetail.current?.status === "Completed" &&
                        initialAssessmentDetail.current?.surveyType?.value !== 23 && 
                        initialAssessmentDetail.current?.surveyType?.value !== 33 ) {
                        if (initialAssessmentDetail.current?.parentFacility) {
                            GetAccountDetail(initialAssessmentDetail.current.parentFacility.value, appUser!.token)
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.facilityTypeID !== 14) {
                                        setShowSESLink(true);
                                    }
                            })
                        } else {
                            setShowSESLink(true);
                        }
                    }
                }).finally(() => setLoading(false));
        } else if (assessmentID === 'new') {
            setAssessmentDetail(CreateDefaultAssessmentDetail(appUser, location));
            initialAssessmentDetail.current = CreateDefaultAssessmentDetail();
        }
    }, [appUser, assessmentID, showRemoveFacilites, location]);

    const validateExistingValues = React.useCallback(async (newAssessmentTypeID: NumericDropDownModel|null) => {
        if (newAssessmentTypeID && assessmentDetail.surveyID === 0) {
            let newParentFacility: NumericDropDownModel|null = assessmentDetail.parentFacility;
            let newFacilities: NumericDropDownModel[] = [];
            let newLanguage: NumericDropDownModel|null = assessmentDetail.language;

            setLoading(true);

            const parentFacilityCheck = GetFacilityTypeAheadSearch(appUser?.token!, undefined, undefined, newAssessmentTypeID?.value, undefined, undefined, true, true);
            const facilitiesCheck = GetFacilityTypeAheadSearch(appUser?.token!, undefined, undefined, newAssessmentTypeID?.value, assessmentDetail.parentFacility?.value, undefined, false, true);
            const languageCheck = GetLanguageOptions(newAssessmentTypeID?.value, appUser?.token!);
            let assignedToUser = assessmentDetail.assignedToUser;

            await Promise.all([parentFacilityCheck, facilitiesCheck, languageCheck])
            .then(async (response) => {
                const data = await Promise.all(response.map((res) => res.json()));

                if (data[0].map((x: NumericDropDownModel) => x.value).indexOf(assessmentDetail.parentFacility?.value) < 0) {
                    newParentFacility = null;
                }
                
                newFacilities = data[1].filter((dataItem:NumericDropDownModel) => {
                    return assessmentDetail.facilities.some((currentItem) => {
                        return dataItem.value === currentItem.value;
                    });
                });

                if (data[2].map((x: NumericDropDownModel) => x.value).indexOf(assessmentDetail.language?.value) < 0) {
                    newLanguage = null;
                }

                if (newAssessmentTypeID.value === 33) {
                    assignedToUser = {
                        label: 'HA.System@user.org',
                        value: 1
                    };
                } else if (assignedToUser?.value === 1) {
                    assignedToUser = appUser ? {label: appUser!.email, value: appUser!.userID} : null
                }

                setAssessmentDetail({
                    ...assessmentDetail,
                    assignedToUser: assignedToUser,
                    parentFacility: newParentFacility,
                    facilities: newFacilities,
                    language: newLanguage,
                    surveyType: newAssessmentTypeID, 
                    digitalHealthTechnologyPartner: null,
                    doNotSendCompletionEmail: false,
                    createBlankSurvey: false
                });
            });
        }

        setLoading(false);
    },[appUser, assessmentDetail]);

    const updateAssessment = React.useCallback((skipValidation?:boolean) => {
        if(!AssessmentDetailModelIsValid(assessmentDetail)){
            setShowValidationText(true);
            return undefined;
        }
        setShowValidationText(false);
        setSaving(true);

        if(assessmentID === 'new'){
            initialAssessmentDetail.current = assessmentDetail;
        }

        FileAssessmentDetail({...assessmentDetail, skipValidation: skipValidation ?? false}, appUser?.token).then(response => {
            return response.json()
        }).then(data => {
            if(data.isValid === false){
                setShowValidationModal(true);
                setAssessmentDetailValidationModel(data);
                return undefined;
            }else{
                if(assessmentID === 'new'){
                    navigate(`/assessments/${data.surveyID}`);
                }else{
                    initialAssessmentDetail.current = data;
                    setAssessmentDetail(data);
                    if(showResendEmailDialogOnSave){
                        setShowResendEmailDialog(true);
                    }
                }
            }
        }).catch(error => {

        }).finally(() => {
            setSaving(false);
            setShowResendEmailDialogOnSave(false);
        });
    },[appUser?.token, assessmentDetail, assessmentID, navigate, showResendEmailDialogOnSave]);

    const deleteAssessment = React.useCallback(() => {
        setShowDeleteDialog(false);
        setSaving(true);
        initialAssessmentDetail.current = assessmentDetail;
        DeleteAssessmentDetail(assessmentDetail.surveyID, appUser?.token).then(response => {
            if(response.status === 200){
                navigate("/assessments");
            }
        }).finally(() => {
            setSaving(false);
        })
    }, [appUser?.token, navigate, assessmentDetail]);

    const loadSESAssessment = async () => {
        setLoading(true);
        let newLanguage: NumericDropDownModel|null = assessmentDetail.language;

        GetLanguageOptions(33, appUser?.token!)
        .then((response) => response.json())
        .then((data) =>  {
            if (data.map((x: NumericDropDownModel) => x.value).indexOf(assessmentDetail.language?.value) > -1) {
                newLanguage = { label: 'English', value:1, };
            }

            if (assessmentDetail.parentFacility) {
                setShowTruncatedFacilities(true);
            };

            navigate("../new", { relative: "path", state: { componentState: {
                ...assessmentDetail,
                assignedToUser: {
                   label: 'HA.System@user.org',
                   value: 1
                },
                assignedToUserID: 1,
                assignedToUserName: 'HA.System@user.org',
                digitalHealthTechnologyPartner: null,
                facilities: assessmentDetail.parentFacility ? [assessmentDetail.parentFacility] : [assessmentDetail.facilities[0]],
                language: newLanguage,
                languageID: newLanguage?.value,
                languageName: newLanguage?.label,
                lockFieldsForSES: true,
                parentFacility: null,
                surveyID: 0,
                surveyType: {
                   value: 33,
                   label: 'Service Experience Survey'
                },
                surveyTypeID:33,
                surveyTypeName: 'Service Experience Survey',
           }}});
        }).finally(() => setLoading(false));
    };

    const getLoadAssessmentUsers = async () => {
        navigate("/users/", { relative: "path", state: { componentState: { ...CreateUserAccountListRequest(), surveyIDs: [{label: assessmentDetail.surveyName, value: assessmentDetail.surveyID}] }}})
    }

    const childMenuContent = (
        <RightMenu>
            <List component="nav">
                <ListItem>
                    <Typography variant="h6">Action Items</Typography>
                </ListItem>
                <GoBack/>
                {userHasPermission("Survey_Detail_Delete", appUser) &&
                <ListItemButton onClick={() => setShowDeleteDialog(true)}>
                    Delete
                </ListItemButton>
                }
                {assessmentDetail.status === "In Review" && userHasPermission("Survey_Detail_Edit", appUser) &&
                <ListItemButton onClick={() => setAssessmentDetail({...assessmentDetail, status: "In Progress"})}>
                    Reset to In Progress
                </ListItemButton>
                }
                {userHasPermission("Survey_Detail_Edit", appUser) &&
                    <ListItemButton onClick={() => navigate("../new", { relative: "path", state: { componentState: { ...assessmentDetail, surveyID: 0 }}})}>
                        Clone Assessment
                    </ListItemButton>
                }
                {userHasPermission("Survey_Detail_Edit", appUser) && showSESLink &&
                    <ListItemButton onClick={ loadSESAssessment }>
                        Create Service Experience Survey
                    </ListItemButton>
                }
                <ListItemButton onClick={() => setShowResendEmailDialog(true)}>
                    Resend Emails
                </ListItemButton>
                {userHasPermission("Survey_Detail_Edit", appUser) && (assessmentDetail.status === 'In Progress' || assessmentDetail.status === 'Not Started') && assessmentDetail.facilities.length > 1 &&
                <ListItemButton onClick={() => setShowRemoveFacilites(true)}>
                    Remove Facilites
                </ListItemButton>
                }
                <Divider/>
                <ListItem>
                    <Typography variant="h6">Links</Typography>
                </ListItem>
                {assessmentDetail.parentFacility && userHasPermission("Account_Detail_View", appUser) &&
                <ListItemButton component={Link} to={`/accounts/${assessmentDetail.parentFacility!.value}`}>
                    {assessmentDetail.parentFacility.label}
                </ListItemButton>
                }
                {userHasPermission("Account_Detail_View", appUser) && assessmentDetail.facilities.length > 0 && assessmentDetail.facilities.map(facility => 
                <ListItemButton component={Link} to={`/accounts/${facility.value}`} key={facility.value}>{facility.label}</ListItemButton>
                )
                }
                {userHasPermission("Document_List_View", appUser) &&
                <ListItemButton onClick={() => navigate("/documents/", { relative: "path", state: { componentState: { ...CreateDefaultDocumentSearchRequest(undefined, undefined), isCurrent: false, assessmentIDs: [{value: assessmentDetail.surveyID, label: assessmentDetail.surveyName}] }}})}>
                    Documents
                </ListItemButton>
                }
                {userHasPermission('Global_AssessmentLinks_View', appUser) &&
                <>
                    <ListItemButton component='a' href={`${process.env.REACT_APP_EXTERNAL_BASE_SURVEY_URL}surveyLanding/${assessmentDetail.surveyGUID}/home`} target="_blank">
                        External Assessment
                    </ListItemButton>
                    <ListItemButton component='a' href={`${process.env.REACT_APP_INTERAL_BASE_SURVEY_URL}surveys/${assessmentDetail.surveyID}`} target="_blank">
                        Internal Assessment
                    </ListItemButton>                      
                </>
                }
                {userHasPermission("UserAccount_List_View", appUser) &&
                    <ListItemButton onClick={ getLoadAssessmentUsers }>
                        Assessment Users
                    </ListItemButton>
                }
            </List>
        </RightMenu>
    );

    return (
        <BaseLayout childMenu={(assessmentID !== 'new') ? childMenuContent : false} childMenuOpen={(assessmentID !== 'new')}>
            {(saving || loading) && <Loading />}          
            {userHasPermission("Survey_Detail_Edit", appUser) &&
            <Box display="flex" justifyContent="flex-end">
                <Fab size="medium" color="primary" aria-label="save" variant="extended" onClick={() => updateAssessment()} disabled={assessmentDetail.status === "Preparing" || !isDirty}>
                    {assessmentID === 'new' ? "Create" : "Save"}
                </Fab>
            </Box>
            }
            <Typography variant="h6" gutterBottom>
                Assessment Details
            </Typography>
            <Grid container spacing={3} sx={{pb:10}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Assessment ID"
                        disabled
                        value={assessmentDetail.surveyID}
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Assessment GUID"
                        disabled
                        value={assessmentDetail.surveyGUID ?? ""}
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Status"
                        disabled
                        value={assessmentDetail.status ?? ""}
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={assessmentDetail.surveyName}
                        fullWidth
                        disabled
                        variant="standard"
                        label="Assessment Name"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={assessmentDetail.surveyType}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => {
                            validateExistingValues(newValue);
                        }}
                        searchCallback={(searchTerm?: string) => GetAssessmentTypeTypeAheadSearch(appUser?.token!, searchTerm)}
                        label="Assessment Type"
                        required
                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) ||  assessmentID !== 'new' || assessmentDetail.lockFieldsForSES}
                        error={showValidationText && !assessmentDetail.surveyType}
                        helperText={showValidationText && !assessmentDetail.surveyType ? "This field is required." : undefined}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={assessmentDetail.parentFacility}
                        label="Parent Facility/Network"
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAssessmentDetail({...assessmentDetail, parentFacility: newValue, facilities: []})}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, assessmentDetail.surveyType ? assessmentDetail.surveyType.value : undefined, undefined, undefined, true, null)}
                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentID !== 'new' || !assessmentDetail.surveyType || assessmentDetail.lockFieldsForSES}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TypeAheadMulti
                        value={assessmentDetail.facilities}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel[]) => {
                            if(!assessmentDetail.parentFacility && assessmentDetail.facilities.length > 0){
                                let singleFacility = newValue.filter(x => x.value !== assessmentDetail.facilities[0].value);
                                setAssessmentDetail({...assessmentDetail, facilities: singleFacility});
                            }else{
                                setAssessmentDetail({...assessmentDetail, facilities: newValue});
                            }
                        }}
                        searchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, assessmentDetail.surveyType ? assessmentDetail.surveyType.value : undefined, assessmentDetail.parentFacility ? assessmentDetail.parentFacility.value : undefined, undefined, false, false)}
                        selectAllSearchCallback={(searchTerm?: string) => GetFacilityTypeAheadSearch(appUser?.token!, searchTerm, undefined, assessmentDetail.surveyType ? assessmentDetail.surveyType.value : undefined, assessmentDetail.parentFacility ? assessmentDetail.parentFacility.value : undefined, undefined, false, true)}
                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentID !== 'new' || !assessmentDetail.surveyType}
                        label="Facilities"
                        required
                        error={showValidationText && assessmentDetail.facilities.length === 0}
                        helperText={showValidationText && assessmentDetail.facilities.length === 0 ? "At least one facility is required." : undefined}
                        selectAllEnabled={!!assessmentDetail.parentFacility}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LanguageDropDown
                        value={assessmentDetail.language}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAssessmentDetail({...assessmentDetail, language: newValue})}
                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) || !assessmentDetail.surveyType}
                        surveyTypeID={assessmentDetail.surveyType ? assessmentDetail.surveyType.value : null}
                        error={showValidationText && !assessmentDetail.language}
                        helperText={showValidationText && !assessmentDetail.language ? "The field is required." : !assessmentDetail.surveyType ? "Please select a assessment type first." :  undefined}
                        required
                        isSurveyTypeAgnostic={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={assessmentDetail.digitalHealthTechnologyPartner}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => setAssessmentDetail({...assessmentDetail, digitalHealthTechnologyPartner: newValue})}
                        searchCallback={(searchTerm?: string) => GetDHTPTypeAhead(searchTerm, assessmentDetail.surveyType ? assessmentDetail.surveyType.value : undefined, appUser?.token!)}
                        label="Digital Health Technology Partner"
                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) || !assessmentDetail.surveyType || assessmentDetail.status === "Preparing" || assessmentDetail.status === "Submitted to HA" || assessmentDetail.status === "Completed" || assessmentDetail.lockFieldsForSES}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TypeAhead
                        value={assessmentDetail.assignedToUser}
                        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: NumericDropDownModel | null) => {
                            setAssessmentDetail({...assessmentDetail, assignedToUser: newValue});
                            setShowResendEmailDialogOnSave(true);
                        }}
                        searchCallback={(searchTerm?: string) => GetUserTypeAheadSearch(appUser?.token!, searchTerm, true)}
                        required
                        label="Assigned To"
                        error={showValidationText && !assessmentDetail.assignedToUser}
                        helperText={showValidationText && !assessmentDetail.assignedToUser ? "This field is required." : undefined}
                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentDetail.lockFieldsForSES || assessmentDetail.surveyType?.value === 33 }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel 
                            required={assessmentDetail.surveyType?.value !== 28 && 
                                assessmentDetail.surveyType?.value !== 25 && 
                                assessmentDetail.surveyType?.value !== 19 &&
                                assessmentDetail.surveyType?.value !== 33}
                            variant="standard"
                            error={showValidationText && 
                                !assessmentDetail.surveyReason && 
                                assessmentDetail.surveyType?.value !== 28 && 
                                assessmentDetail.surveyType?.value !== 25 && 
                                assessmentDetail.surveyType?.value !== 19 &&
                                assessmentDetail.surveyType?.value !== 33}
                            disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentID !== 'new' || 
                                assessmentDetail.surveyType?.value === 28 || 
                                assessmentDetail.surveyType?.value === 25 || 
                                assessmentDetail.surveyType?.value === 19 ||
                                assessmentDetail.surveyType?.value === 33}
                            >Reason for Assessment</InputLabel>
                        <Select
                            value={assessmentDetail.surveyReason}
                            onChange={(event:SelectChangeEvent) => setAssessmentDetail({...assessmentDetail, surveyReason: event.target.value})}
                            fullWidth
                            variant="standard"
                            required={assessmentDetail.surveyType?.value !== 28 && 
                                assessmentDetail.surveyType?.value !== 25 && 
                                assessmentDetail.surveyType?.value !== 19 &&
                                assessmentDetail.surveyType?.value !== 33}
                            disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentID !== 'new' || 
                            assessmentDetail.surveyType?.value === 28 || 
                            assessmentDetail.surveyType?.value === 25 || 
                            assessmentDetail.surveyType?.value === 19 ||
                            assessmentDetail.surveyType?.value === 33}
                            error={showValidationText && 
                                !assessmentDetail.surveyReason && 
                                assessmentDetail.surveyType?.value !== 28 && 
                                assessmentDetail.surveyType?.value !== 25 && 
                                assessmentDetail.surveyType?.value !== 19 &&
                                assessmentDetail.surveyType?.value !== 33}
                        >
                            <MenuItem value="New Assessment">New Assessment</MenuItem>
                            <MenuItem value="Assessment Update">Assessment Update</MenuItem>
                        </Select>
                        {showValidationText && !assessmentDetail.surveyReason && 
                                !assessmentDetail.surveyReason && 
                                assessmentDetail.surveyType?.value !== 28 && 
                                assessmentDetail.surveyType?.value !== 25 && 
                                assessmentDetail.surveyType?.value !== 19 &&
                                assessmentDetail.surveyType?.value !== 33 && 
                            <FormHelperText error>This field is required.</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        freeSolo
                        multiple
                        value={assessmentDetail.recipients ?? []}
                        options={assessmentDetail.recipients ?? []}
                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) }
                        renderInput={
                            (params) => 
                            <TextField 
                                required 
                                {...params} 
                                label="Recipients" 
                                variant="standard"
                                error={showValidationText && assessmentDetail.recipients.length === 0}
                                helperText={showValidationText && assessmentDetail.recipients.length === 0 ? "At least one recipient is required." : "Type in an email address and press 'Enter' to add to the list."}
                            />
                        }
                        onChange={(event: React.SyntheticEvent<Element, Event>, value: string[], reason: AutocompleteChangeReason) => {
                            setShowResendEmailDialogOnSave(true);
                            setAssessmentDetail({...assessmentDetail, recipients: value});
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {assessmentID === "new" &&
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={assessmentDetail.plainText === true}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setAssessmentDetail({...assessmentDetail, plainText: checked})}
                                            disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentID !== "new"}
                                        />
                                    }
                                    label="Send Emails as Plain Text?"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={assessmentDetail.doNotSendCompletionEmail === true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setAssessmentDetail({...assessmentDetail, doNotSendCompletionEmail: checked})}
                                        disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentID !== "new"}
                                    />
                                }
                                label="Do Not Send Completion Email"
                            />
                        </Grid>
                        {(assessmentDetail?.surveyType?.value === 22 || assessmentDetail?.surveyType?.value === 23) &&
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={assessmentDetail.createBlankSurvey === true}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setAssessmentDetail({...assessmentDetail, createBlankSurvey: checked})}
                                            disabled={!userHasPermission("Survey_Detail_Edit", appUser) || assessmentID !== "new"}
                                        />
                                    }
                                    label="Create Empty Assessment"
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date Created"
                        value={assessmentDetail.dateCreated ? moment(assessmentDetail.dateCreated).format("MM/DD/YYYY") : ""}
                        disabled
                        fullWidth
                        variant='standard'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Created By"
                        value={assessmentDetail.createdByUserName ?? ""}
                        disabled
                        fullWidth
                        variant='standard'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date Modified"
                        value={assessmentDetail.dateModified ? moment(assessmentDetail.dateModified).format("MM/DD/YYYY") : ""}
                        disabled
                        fullWidth
                        variant='standard'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Modified By"
                        value={assessmentDetail.modifiedByUserName ?? ""}
                        disabled
                        fullWidth
                        variant='standard'
                    />
                </Grid>
            </Grid>
            <AssessmentDetailValidationModal 
                open={showValidationModal} 
                onClose={() => setShowValidationModal(false)} 
                message={assessmentDetailValidationModel?.message ?? ''}
                existingAssessments={assessmentDetailValidationModel?.existingSurveys ?? []}
                onConfirm={() => {
                    setShowValidationModal(false);
                    updateAssessment(true);
                }}
            />
            <DeleteDialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={deleteAssessment}
            />
            <ResendEmailDialog
                open={showResendEmailDialog}
                onClose={() => setShowResendEmailDialog(false)}
                potentialRecipients={assessmentDetail.recipients ?? []}
                assignedUser={assessmentDetail.assignedToUser}
                surveyID={assessmentDetail.surveyID}
                enableCompletionEmailOptions={assessmentDetail.status === "Completed" && assessmentDetail.doNotSendCompletionEmail === false}
            />
            <RemoveFacilitiesDialog
                open={showRemoveFacilites}
                onClose={() => setShowRemoveFacilites(false)}
                facilites={assessmentDetail.facilities}
                surveyID={assessmentDetail.surveyID}
            />
            <Dialog open={showTruncatedFacilities} onClose={() => setShowTruncatedFacilities(false)}>
                <DialogTitle>Parent Facility Set As Facility</DialogTitle>
                <DialogContent>
                    <DialogContentText component={"div"}>The assessment parent facility has been used as the Service Experience Survey facility.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowTruncatedFacilities(false)}>OK</Button>
                </DialogActions>
            </Dialog>
        </BaseLayout>
    );
}

export default AssessmentDetail;