import { ApiBaseRoute } from "../api_helper";

export function GetAssessmentTypeTypeAheadSearch(token: string, searchTerm: string|undefined, facilityTypeID?: number): Promise<Response> {
    return fetch(`${ApiBaseRoute}surveyTypes/typeaheadSearch?searchTerm=${searchTerm ? searchTerm : ''}&facilityTypeID=${facilityTypeID}`, {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
            "accept": "application/json"
        }
    });
};