import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    Stack,
    Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import BaseLayout from "../../layout/BaseLayout.component";
import RightMenu from "../../layout/RightMenu.component";
import { sectionBuilder, GetReportData } from "./AARTemplateBuilder.service";
import { useEffect, useState } from "react";
import { useApiAuthorization } from "../../components/authorization/ApiAuthorizationProvider.component";
import Loading from "../../components/loading/Loading.component";
import { ReportPage, ReportSectionOuter } from "./AARTemplateBuilder.model";
import { userHasPermission } from "../../services/User.service";
import Box from "@mui/material/Box";
import { PrintWrapper } from "./Components/Layout/PrintWrapper.component";

function AARTemplateBuilder() {
    const { appUser } = useApiAuthorization();
    const [isLoading, setIsLoading] = useState(false);
    const { modelID, uniqueID } = useParams();
    const [reportDetails, setReportDetails] = useState<any>([]);

    const scrollTo = (elementId?: any) => {
        window.scrollTo({
            top: document.getElementById(elementId)?.offsetTop! - 65,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if (
            appUser?.token &&
            userHasPermission("AssessmentReports_Detail_View", appUser)
        ) {
            setIsLoading(true);
            GetReportData(
                { uniqueID: uniqueID, modelID: modelID },
                appUser.token
            )
                .then((response) => response.json())
                .then((data) => {
                    setReportDetails(data);
                })
                .finally(() => setIsLoading(false));
        }
    }, [appUser?.token, modelID, uniqueID, appUser]);

    const childMenuContent = (
        <RightMenu>
            <List component="nav">
                <ListItem>
                    <Typography variant="h6">Action Items</Typography>
                </ListItem>
                <ListItemButton onClick={() => window.print()}>
                    Print Report
                </ListItemButton>
                <Divider />
                <ListItem>
                    <Typography variant="h6">Navigation</Typography>
                </ListItem>
                {reportDetails.map((page: any, index: number) => {
                    return (
                        <ListItemButton
                            key={index}
                            component={Link}
                            to={`#page-` + index}
                            onClick={() => scrollTo(`page-` + index)}
                        >
                            {page.pageTitle}
                        </ListItemButton>
                    );
                })}
            </List>
        </RightMenu>
    );

    return (
        <PrintWrapper>
            <BaseLayout childMenu={childMenuContent}>
                {isLoading && <Loading />}
                <Stack>
                    <Box className="content">
                        {reportDetails.map(
                            (page: ReportPage, index: number) => {
                                return (
                                    <>
                                        <Box
                                            key={index}
                                            id={`page-` + index}
                                            className={"testPage"}
                                            sx={{
                                                "@media print": {
                                                    fontSize: "14px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px"
                                                },
                                            }}
                                        >
                                            {page.sections.map(
                                                (
                                                    section: ReportSectionOuter,
                                                    index: number
                                                ) =>
                                                    sectionBuilder(
                                                        section,
                                                        index
                                                    )
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "block",
                                                width: "100%",
                                                borderBottom:
                                                    "1px solid rgb(0 0 0 / 12%)",
                                                margin: "50px 0px",
                                                "@media print": {
                                                    display: "none",
                                                },
                                            }}
                                        ></Box>
                                    </>
                                );
                            }
                        )}
                    </Box>
                </Stack>
            </BaseLayout>
        </PrintWrapper>
    );
}

export default AARTemplateBuilder;
