import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { TableWrapper } from "../Layout/TableWrapper.component";
import { StyledTable } from "./StyledTable.component";

const applyClassFocusArea = (row:any, rowName: string, row_hg: number) => {

    if (row[rowName] === "N/A") {
        return "" as string;
    }

    if (row.id < 3) {
        return "bg-blue";
    }

    if (rowName === "col2" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col3" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col4" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col5" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (rowName === "col6" && row[rowName+"_hg"] === 1) {
        return "bg-red"
    }

    if (row[rowName] > 69.5) {
        return "bg-green";
    }

    return "bg-yellow" as string;
}

const renderCell = (row: any, rowName: string) => {
    if (row.id !== 1 && row[rowName] !== "N/A") {
        return row[rowName].toLocaleString() + "%";
    }

    return row[rowName];
}


export const FocusAreaTable = (item: any) => (
    <TableWrapper>
        <TableContainer>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                           
                        </TableCell>
                        <TableCell align="center">
                        Data Capture & Health Information Exchange
                        </TableCell>         
                        <TableCell align="center">
                        Patient Engagement
                        </TableCell>     
                        <TableCell align="center">
                        Healthcare Analytics & Outcomes Measurement
                        </TableCell>     
                        <TableCell align="center">
                        Resilience Management
                        </TableCell>     
                        <TableCell align="center">
                        Clinical User Adoption
                        </TableCell>                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    {item.data.rowData.map((row: any) => (
                        <TableRow
                            key={row.name}
                        >
                            <TableCell align="center">{row.col1}</TableCell>
                            <TableCell align="center" className={applyClassFocusArea(row, "col2", row.col2_hg)}>{renderCell(row, "col2")}</TableCell>
                            <TableCell align="center" className={applyClassFocusArea(row, "col3", row.col3_hg)}>{renderCell(row, "col3")}</TableCell>
                            <TableCell align="center" className={applyClassFocusArea(row, "col4",  row.col4_hg)}>{renderCell(row, "col4")}</TableCell>
                            <TableCell align="center" className={applyClassFocusArea(row, "col5",  row.col5_hg)}>{renderCell(row, "col5")}</TableCell>
                            <TableCell align="center" className={applyClassFocusArea(row, "col6",  row.col6_hg)}>{renderCell(row, "col6")}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    </TableWrapper>
);







// export const FocusArea = (item: any) => {

//     return (
//         <Grid
//             sx={{
//                 display: "flex",
//                 width: "100%",
//                 flexGrow: 1,
//                 marginTop: "2rem",
//                 marginBottom: "2rem",
                
//             }}
//         >
//             <StyledDataGrid
//                 disableVirtualization={true}
//                 autoHeight={true}
//                 getRowHeight={() => "auto"}
//                 density="compact"
//                 sx={{
//                     "& .MuiDataGrid-columnHeader": {
//                     backgroundColor: "#fff"
//                 },
//                 "& .MuiDataGrid-columnHeaderTitle": {
//                     backgroundColor: "#fff",
//                     fontWeight: 400,
//                     color: "#000000",  
//                     textAlign: "center"
//                 },
//                 }}
//                 columns={[
//                     {
//                         field: "col1",
//                         headerName: "",
//                         flex: 1,
//                         filterable: false,
//                         sortable: false,
//                         align: "center",
//                         headerAlign: "center"
//                     },
//                     {
//                         field: "col2",
//                         headerName:
//                             "Data Capture & Health Information Exchange",
//                         flex: 1,
//                         filterable: false,
//                         sortable: false,
//                         align: "center",
//                         headerAlign: "center",
//                         renderCell: (params: any) => {
//                             if (params.id !== 1 && params.value !== "N/A") {
//                                 return params.value.toLocaleString() + "%";
//                             }
//                         },
//                         cellClassName: (params: any) =>
//                             applyClassFocusArea(params),
//                     },
//                     {
//                         field: "col3",
//                         headerName: "Patient Engagement",
//                         flex: 1,
//                         filterable: false,
//                         sortable: false,
//                         align: "center",
//                         headerAlign: "center",
//                         renderCell: (params: any) => {
//                             if (params.id !== 1 && params.value !== "N/A") {
//                                 return params.value.toLocaleString() + "%";
//                             }
//                         },
//                         cellClassName: (params: any) =>
//                             applyClassFocusArea(params),
//                     },
//                     {
//                         field: "col4",
//                         headerName:
//                             "Healthcare Analytics & Outcomes Measurement",
//                         flex: 1,
//                         filterable: false,
//                         sortable: false,
//                         align: "center",
//                         headerAlign: "center",
//                         renderCell: (params: any) => {
//                             if (params.id !== 1 && params.value !== "N/A") {
//                                 return params.value.toLocaleString() + "%";
//                             }
//                         },
//                         cellClassName: (params: any) =>
//                             applyClassFocusArea(params),
//                     },
//                     {
//                         field: "col5",
//                         headerName: "Resilience Management",
//                         flex: 1,
//                         filterable: false,
//                         sortable: false,
//                         align: "center",
//                         headerAlign: "center",
//                         renderCell: (params: any) => {
//                             if (params.id !== 1 && params.value !== "N/A") {
//                                 return params.value.toLocaleString() + "%";
//                             }
//                         },
//                         cellClassName: (params: any) =>
//                             applyClassFocusArea(params),
//                     },
//                     {
//                         field: "col6",
//                         headerName: "Clinical User Adoption",
//                         flex: 1,
//                         filterable: false,
//                         sortable: false,
//                         align: "center",
//                         headerAlign: "center",
//                         renderCell: (params: any) => {
//                             if (params.id !== 1 && params.value !== "N/A") {
//                                 return params.value.toLocaleString() + "%";
//                             }
//                         },
//                         cellClassName: (params: any) =>
//                             applyClassFocusArea(params),
//                     },
//                 ]}
//                 rows={item.data.rowData}
//                 disableColumnFilter
//                 disableColumnMenu
//                 disableColumnResize
//                 disableColumnSelector
//                 disableChildrenSorting
//                 disableColumnReorder
//                 disableDensitySelector
//                 disableMultipleRowSelection
//                 disableRowSelectionOnClick
//                 hideFooter
//             />
//         </Grid>
//     );
// };